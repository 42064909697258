import React, { useEffect, useRef } from "react";
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  DoughnutController,
  LinearScale,
  LineController,
  LineElement,
  Legend,
  PointElement,
  Tooltip,
} from "chart.js";
import tokens from "@tbml/tokens";
import { H4 } from "../Typography";

Chart.register(
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  DoughnutController,
  Legend,
  LineElement,
  LinearScale,
  LineController,
  PointElement,
  Tooltip
);
Chart.defaults.font.family = tokens.font.h1.fontFamily.value;

const GRAPH_COLORS = {
  BLUE: "#38A3EB",
  GREEN: "#34B82A",
  RED: "#FF0000",
  GRAY: "#BEBEBE",
};

type Props = {
  data: {
    noNeutral: number;
    noPositive: number;
    noNegative: number;
  };
};

export default function SentimentWidget({ data }: Props): JSX.Element | null {
  const canvasEl = useRef(null);
  console.log("data", data);

  useEffect(() => {
    const ctx = canvasEl.current;
    if (!ctx) {
      return () => {
        // nothing to clean
      };
    }

    const chart = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: ["noPositive", "noNeutral", "noNegative"],
        datasets: [
          {
            label: "Documents",
            data: [data.noPositive, data.noNeutral, data.noNegative],
            backgroundColor: [
              GRAPH_COLORS.GREEN,
              GRAPH_COLORS.GRAY,
              GRAPH_COLORS.RED,
            ],
          },
        ],
      },
    });
    return () => chart.destroy();
  }, [data]);

  return (
    <div style={{ maxHeight: "400px", maxWidth: "550px" }}>
      <H4>Overall Sentiment</H4>
      <canvas ref={canvasEl} width="550" height="400" />
    </div>
  );
}
