import {
  TonalityDataPoint,
  VisibilityAndTonalityDataPoint,
} from "@tbml/api-interface/graphql";

/**
 * ISO 8601 formatted date - 2024-05-28
 */
export function formatDate(date: Date): string {
  return `${date.getUTCFullYear()}-${(date.getMonth() + 1)
    .toString(10)
    .padStart(2, "0")}-${date.getDate().toString(10).padStart(2, "0")}`;
}

/**
 *  given the `startDate` (inclusive) and `endDate` (inclusive) fills missing datapoints between them.
 */
export const makeGetFilledData =
  <T extends TonalityDataPoint | VisibilityAndTonalityDataPoint>(
    emptyPoint: T
  ) =>
  (startDate: Date, endDate: Date, rawDataPoints: T[]): T[] => {
    let innerDataPoints: T[] = [];
    innerDataPoints = rawDataPoints.filter(
      ({ date }) => date.localeCompare(formatDate(startDate)) >= 0
    );
    const startingPoint = new Date(startDate.getTime());
    while (formatDate(startingPoint).localeCompare(formatDate(endDate)) <= 0) {
      const date = formatDate(startingPoint);
      const innerPoint = innerDataPoints.find((p) => p.date === date);

      if (!innerPoint) {
        innerDataPoints.push({
          ...emptyPoint,
          date: formatDate(startingPoint),
        });
      }
      startingPoint.setDate(startingPoint.getDate() + 1);
    }
    return innerDataPoints;
  };

export const getFilledDataForTonality = makeGetFilledData<TonalityDataPoint>({
  noPositive: 0,
  noNegative: 0,
  noNeutral: 1,
  date: new Date(),
});

export const getFilledDataForVisibilityAndTonality =
  makeGetFilledData<VisibilityAndTonalityDataPoint>({
    noPositive: 0,
    noNegative: 0,
    noNeutral: 1,
    date: new Date(),
    visibility: 0,
  });
