import * as React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { useSkeleton } from "@tbml/shared-dependencies/react-skeletons";
import { Image } from "@tbml/components/Image";
import { Skeleton } from "@tbml/components/Skeleton";
import { Customer } from "@tbml/api-interface/graphql";
import useScrollSection from "@tbml/components/ScrollSections/useScrollSection";
import {
  CUSTOMER_IMAGE_MAX_HEIGHT,
  CUSTOMER_IMAGE_MAX_HEIGHT_MOBILE,
  DEFAULT_CUSTOMER_IMAGE_WIDTH,
} from "./styles";

export { CUSTOMER_IMAGE_MAX_HEIGHT, DEFAULT_CUSTOMER_IMAGE_WIDTH };

export type Props = {
  customerHeaderImage?: Customer["image"];
  isLoading?: boolean;
};

function CustomerHeaderImageSkeleton(): JSX.Element {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  if (isDesktop) {
    return (
      <Skeleton
        aria-label="loading header image..."
        width={`${DEFAULT_CUSTOMER_IMAGE_WIDTH}px`}
        height={`${CUSTOMER_IMAGE_MAX_HEIGHT}px`}
        maxWidth="100%"
      />
    );
  }
  return (
    <Skeleton
      aria-label="loading header image..."
      height={`${CUSTOMER_IMAGE_MAX_HEIGHT_MOBILE}px`}
      maxWidth="100%"
    />
  );
}

const computeSource = (originPath?: string) => {
  if (!originPath) return originPath;
  if (originPath.endsWith(".gif")) return originPath;

  return `${originPath}?fit=clip&auto=enhance`;
};

export function CustomerHeaderImage({
  customerHeaderImage = undefined,
  isLoading = false,
}: Props): JSX.Element | null {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const { withSkeleton } = useSkeleton({
    Skeleton: CustomerHeaderImageSkeleton,
  });

  const scrollSectionProps = useScrollSection();

  if (isLoading) return <CustomerHeaderImageSkeleton />;
  if (!customerHeaderImage) return null;

  const maxHeight = isDesktop
    ? CUSTOMER_IMAGE_MAX_HEIGHT
    : CUSTOMER_IMAGE_MAX_HEIGHT_MOBILE;

  return withSkeleton(
    customerHeaderImage && (
      <>
        <div {...scrollSectionProps} />
        <Image
          aria-label="header image"
          alt="header image"
          src={computeSource(customerHeaderImage.originPath)}
          width={DEFAULT_CUSTOMER_IMAGE_WIDTH}
          height={Math.min(customerHeaderImage.mediaHeight, maxHeight)}
          maxHeight={
            isDesktop
              ? CUSTOMER_IMAGE_MAX_HEIGHT
              : CUSTOMER_IMAGE_MAX_HEIGHT_MOBILE
          }
          placeholder="blur"
          priority
          allowCropping
          autoSize
        />
      </>
    )
  );
}
