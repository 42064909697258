import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { useSkeleton } from "@tbml/shared-dependencies/react-skeletons";
import { Article, useArticles } from "@tbml/hooks/useArticles";
import { ArticleStatus, Query, SortSection } from "@tbml/api-interface/graphql";
import {
  isClientError,
  isLicenseError,
  isLicenseFreeError,
} from "@tbml/shared-dependencies/license";
import { LicenseState } from "@tbml/api-interface/licence";
import { useRouter } from "@tbml/shared-dependencies/router";
import { Text } from "@tbml/components/Typography";
import { atom, useRecoilState } from "@tbml/shared-dependencies/recoil";
import { ArticleSlide } from "../ArticleSlide";
import { SLIDER_ITEM_WIDTH, SliderItem } from "../Slider/styles";
import { Slider } from "../Slider";
import { useCurrentIssueIdValue } from "../../state/currentIssueId";
import { useSelectedSortSectionIdState } from "../../state/selectedSortSection";
import { getSliderStepKey, useResetSliderStep } from "../../state/sliderStep";
import { ArticlesSlider } from "../ArticlesSlider";
import { LiteBadge, SectionText } from "./styles";

export type Props = {
  sortSections: SortSection[];
  inboxProductIssueId: string | undefined;
};

const recentArticleIndexAtom = atom<number>({
  key: "recentArticleIndex",
  default: 0,
});

const useRecentArticleState = () => useRecoilState(recentArticleIndexAtom);

function AdditionalCoverageArticlesSkeleton(): JSX.Element {
  const skeletonKeys = React.useMemo(
    () =>
      new Array(5)
        .fill(null)
        .map((_, index) => `AdditionalCoverage_skeleton_${index}`),
    []
  );
  return (
    <Slider
      aria-label="loading additional coverage articles"
      activeStep={{ step: 0, update: "SKIP" }}
      itemCount={skeletonKeys.length}
    >
      {skeletonKeys.map((ariaLabel) => (
        <ArticleSlide key={ariaLabel} />
      ))}
    </Slider>
  );
}

function isDefined<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export function AdditionalCoverageArticles({
  inboxProductIssueId,
  sortSections,
}: Props): JSX.Element {
  const shouldFetchArticles = !!inboxProductIssueId;
  const sortSectionsIds = sortSections.map((s) => s.id);
  const {
    data,
    status,
    error: articleError,
  } = useArticles().query({
    filter: {
      ...(inboxProductIssueId
        ? { inboxProductIssueIds: [inboxProductIssueId] }
        : {}),
      sortSectionIds: sortSectionsIds,
      status: [
        ArticleStatus.Confirmed,
        ArticleStatus.ConfirmedAutomatically,
        ArticleStatus.Proposed,
      ],
    },
    enabled: !!inboxProductIssueId,
  });
  const router = useRouter();
  const {
    query: { articleId: selectedArticleId },
  } = router;

  const isLoading = shouldFetchArticles && status === "pending";
  const [tabIndex, setTabIndex] = useState(0);

  const licenseState: LicenseState = useMemo(() => {
    if (isLicenseError(articleError)) return "archived";
    if (isLicenseFreeError(articleError)) return "license-free";
    return "licensed";
  }, [articleError]);

  const articles = useMemo(() => {
    if (isClientError(articleError) && licenseState !== "licensed") {
      const { getArticles } = articleError.response.data as Query;
      return getArticles?.articles.filter(isDefined) ?? [];
    }

    return data;
  }, [articleError, data, licenseState]);

  const issueId = useCurrentIssueIdValue();
  const [selectedSortSectionId, setSelectedSortSectionId] =
    useSelectedSortSectionIdState(issueId);

  const resetSliderStep = useResetSliderStep();
  const { withSkeleton } = useSkeleton({
    isLoading: (isParentSkeletonLoading) =>
      isParentSkeletonLoading || isLoading,
    Skeleton: AdditionalCoverageArticlesSkeleton,
  });
  let firstSortSectionId = "";
  if (sortSections[0]) {
    firstSortSectionId = sortSections[0].id;
  }
  const displayedSortSectionId = selectedSortSectionId || firstSortSectionId;

  const [recentArticleIndex, setRecentArticleIndex] = useRecentArticleState();

  const groupedArticles = useMemo(() => {
    const grouped: Article[][] = [];
    const sortSectionsIndexes = new Map<string, number>();

    let index = 0;
    for (const sections of sortSectionsIds) {
      grouped.push([]);
      sortSectionsIndexes.set(sections, index);
      index += 1;
    }
    const inputArticles =
      articles?.filter((art) =>
        sortSectionsIds.includes(art.sortSection?.id ?? "")
      ) ?? [];

    inputArticles.sort((a, b) => {
      const rankA = a.articleSortSectionRank ?? 0;
      const rankB = b.articleSortSectionRank ?? 0;
      return rankA - rankB; // Ascending order by rank
    });

    for (const article of inputArticles) {
      if (article.sortSection) {
        const { id } = article.sortSection;
        const sectionIndex = sortSectionsIndexes.get(id);
        if (sectionIndex === undefined) {
          throw new Error("Couldn't find sort section in the map.");
        }
        grouped[sectionIndex].push(article);
      }
    }
    return grouped;
  }, [articles, sortSectionsIds]);

  const updateTabIndex = useCallback(
    (itemIndex: number) => {
      let groupIndex = 0;
      let articleIndex = 0;
      const map = new Map<number, number>();
      groupedArticles.forEach((group) => {
        group.forEach(() => {
          map.set(articleIndex, groupIndex);
          articleIndex += 1;
        });
        groupIndex += 1;
      });

      const articleGroup = map.get(itemIndex);
      if (articleGroup === undefined) {
        return;
      }
      if (articleGroup < tabIndex) {
        setTabIndex(articleGroup);
        const newTab = sortSections[articleGroup].id;
        setSelectedSortSectionId(newTab);
      } else if (articleGroup > tabIndex) {
        setTabIndex(articleGroup);
        const newTab = sortSections[articleGroup].id;
        setSelectedSortSectionId(newTab);
      }
    },
    [groupedArticles, tabIndex, setSelectedSortSectionId, sortSections]
  );

  useEffect(() => {
    let articleIndex = 0;
    for (const group of groupedArticles) {
      for (const art of group) {
        if (art.id === selectedArticleId) {
          setRecentArticleIndex(articleIndex);
          updateTabIndex(articleIndex);
          return;
        }
        articleIndex += 1;
      }
    }
    // this only needs to be updated once the article id from the router changes
    // so skipping other deps is intentional
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArticleId]);

  return withSkeleton(
    <>
      <Tabs
        value={displayedSortSectionId}
        onChange={(_, newSortSectionId: string) => {
          const key = getSliderStepKey(issueId, newSortSectionId);
          resetSliderStep(key);
          setSelectedSortSectionId(newSortSectionId);
          const newTabIndex = sortSections.findIndex(
            (section) => section.id === newSortSectionId
          );
          setTabIndex(newTabIndex);
          let newInitialIndex = 0;
          for (let k = 0; k < groupedArticles.length; k += 1) {
            const group = groupedArticles[k];
            if (k !== newTabIndex) {
              newInitialIndex += group.length;
            } else {
              break;
            }
          }
          setRecentArticleIndex(newInitialIndex);
        }}
        aria-label="Sort sections"
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        {sortSections.map(({ id, name, count }) => (
          <Tab
            key={id}
            label={
              <SectionText>
                <Text paragraph={false}>{name}</Text>
                <LiteBadge>{count ?? 0}</LiteBadge>
              </SectionText>
            }
            value={id}
          />
        ))}
      </Tabs>
      <Box
        aria-label="Articles of sort sections"
        display="flex"
        sx={{ position: "relative", marginBottom: "24px", marginTop: "24px" }}
      >
        <ArticlesSlider
          itemWidth={300}
          initialIndex={recentArticleIndex}
          onIndexChange={(itemIndex) => {
            updateTabIndex(itemIndex);
          }}
          groupedItems={groupedArticles}
          renderItem={(article) => (
            <SliderItem width={SLIDER_ITEM_WIDTH}>
              <ArticleSlide
                article={article}
                isSelected={selectedArticleId === article.id}
                licenseState={licenseState}
                href={`/articles/${article.id}?issueId=${issueId}&from=addcov`}
                animation="slideLeft"
              />
            </SliderItem>
          )}
        />
      </Box>
    </>
  );
}
