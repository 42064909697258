import * as React from "react";
import { AlertTitle } from "@mui/material";
import * as Sentry from "@sentry/react";
import { Link } from "@tbml/components/Link";
import { SocialMediaPlatform } from "@tbml/api-interface/socialMedia";
import { Article } from "@tbml/hooks/useArticles";
import {
  SocialMediaArticleSkeleton,
  SOCIAL_MEDIA_MIN_WIDTH,
} from "@tbml/components/SocialMediaPlatform";
import { Slider } from "../Slider";
import { SliderItem } from "../Slider/styles";
import { ConsentWarningPlaceholder } from "./styles";

export type SocialMediaArticlesProps = {
  articles: Article[];
  platform: SocialMediaPlatform;
  loading?: boolean;
};

type SocialMediaSliderItemSkeletonProps = {
  platform: SocialMediaPlatform;
  count: number;
  isSocialMediaConsentGiven?: boolean;
};

export function SocialMediaSliderPlaceholder({
  platform,
  count,
  isSocialMediaConsentGiven = false,
}: SocialMediaSliderItemSkeletonProps): JSX.Element {
  const skeletonKeys = React.useMemo(
    () =>
      new Array(count)
        .fill(null)
        .map((_, index) => `${platform.label}_skeleton_${index}`),
    [count, platform.label]
  );

  return (
    <Slider
      activeStep={{ step: 0, update: "SKIP" }}
      aria-label="Social Media Skeleton"
      itemCount={skeletonKeys.length}
    >
      <>
        {skeletonKeys.map((key) => (
          <SliderItem
            key={key}
            width={platform.embedWidth ?? SOCIAL_MEDIA_MIN_WIDTH}
          >
            <SocialMediaArticleSkeleton platform={platform} />
          </SliderItem>
        ))}
        {!isSocialMediaConsentGiven && (
          <ConsentWarningPlaceholder severity="warning">
            <AlertTitle>Missing consent in Privacy Preferences</AlertTitle>
            To show social media content, the cookie policy setting of{" "}
            <strong>&quot;Experience&quot;</strong> is required.
            <br />
            <Link
              href="#"
              onClick={() => {
                if (window) {
                  try {
                    // eslint-disable-next-line no-underscore-dangle
                    window._iub.cs.api.openPreferences();
                  } catch (e) {
                    Sentry.captureException(
                      new Error("Iubenda API is not accessible")
                    );
                  }
                }
              }}
            >
              Click here to change Privacy Preferences
            </Link>
          </ConsentWarningPlaceholder>
        )}
      </>
    </Slider>
  );
}
