import { useEffect } from "react";
import router, { useRouter } from "@tbml/shared-dependencies/router";

export const useHashNavigation = (
  param: string,
  dependencies: unknown[]
): void => {
  const { asPath } = useRouter();
  useEffect(() => {
    const hashParams = /#(.*)/.exec(asPath)?.[1];

    if (!hashParams || dependencies.some((x) => !x)) return;
    if (!hashParams.split(";").includes(param)) return;

    const element = document.getElementById(param);
    if (element) {
      setTimeout(() => {
        router.replace(window.location.pathname, undefined, { shallow: true });
        element.scrollIntoView();
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, param]);
};
