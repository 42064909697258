import React, { ReactElement } from "react";
import { Box, styled, TypographyProps } from "@mui/material";
import ReactPlayer from "react-player";
import { Image, Props as ImageProps } from "@tbml/components/Image";
import tokens from "@tbml/tokens";
import { H4 } from "@tbml/components/Typography";
import { DeltaContent } from "@tbml/components/DeltaContent";
import { Props } from "./StoryCardMedia";

export const HOVER_TRANSLATE_OFFSET = tokens.spacing.verticalXs.value;

export const CARD_WIDTH = tokens.breakpoint.md.minWidth.original.value;
export const MEDIA_HEIGHT = 450;
export const MEDIA_WIDTH = "300px";

export type StoryPreviewAlignment = "column" | "row";

/* stylelint-disable value-no-vendor-prefix */
/* stylelint-disable property-no-vendor-prefix */
export const ClampedStoryTitle = styled(
  (props: { hovered?: boolean } & TypographyProps) => <H4 {...props} />,
  {
    shouldForwardProp: (prop) => prop !== "hovered",
  }
)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  padding-bottom: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${({ hovered, theme }) =>
    hovered ? theme.palette.primary.main : false};
  transition: ${({ theme }) => {
    if (!theme || !theme.transitions) return false;
    const {
      transitions: { easing, duration },
    } = theme;
    return `color ${easing.easeInOut} ${duration.shortest}ms`;
  }};
`;

export const ResponsiveReactPlayer = styled(ReactPlayer)<{ ratio: number }>`
  aspect-ratio: ${(props) => props.ratio};
`;

export const StoryPreviewMediaBackgroundContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "loading",
})<{
  loading: boolean;
  width?: number;
  height?: number;
}>`
  width: 100%;
  max-height: ${MEDIA_HEIGHT}px;
  position: relative;
  background: ${({ theme, loading }) => {
    if (!theme || !theme.palette) return false;
    const {
      palette: {
        action: { disabled },
        primary: { main },
      },
    } = theme;
    return loading ? disabled : main;
  }};

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    height: auto;
  }
`;

export const ComponentBackground = styled("div")`
  background: ${({ theme }) => {
    if (!theme || !theme.palette) return false;
    const {
      palette: { background },
    } = theme;
    return background.default;
  }};
`;

export type StoryPreviewMediaProps = ImageProps & {
  hovered: boolean;
  as?: Props["as"];
};
export const StoryPreviewMedia = styled(
  ({
    unoptimized,
    as: Component,
    className,
    maxHeight,
    showOriginalSize,
    ...props
  }: StoryPreviewMediaProps) => {
    if (Component) {
      return (
        <ComponentBackground className={className} {...props}>
          <Component {...props} />
        </ComponentBackground>
      );
    }

    return (
      <Box position="relative" className={className}>
        <Image
          {...props}
          autoSize
          maxHeight={maxHeight}
          showOriginalSize={showOriginalSize}
        />
      </Box>
    );
  },
  {
    shouldForwardProp: (prop) => prop !== "hovered",
  }
)`
  object-fit: cover;
  transform: ${({ hovered }) =>
    hovered
      ? `translate(-${HOVER_TRANSLATE_OFFSET}, -${HOVER_TRANSLATE_OFFSET})`
      : "translate(-4px, -4px)"};
  transition: ${({ theme }) => {
    const {
      transitions: { easing, duration },
    } = theme;
    return `transform ${easing.easeInOut} ${duration.shortest}ms`;
  }};
`;

export const StoryPreviewBody = styled(DeltaContent)`
  color: ${({ theme }) => {
    if (!theme || !theme.palette) return false;
    const {
      palette: {
        text: { secondary },
      },
    } = theme;
    return secondary;
  }};
`;

type PlayerWrapperProps = {
  children: React.ReactNode;
};

export function PlayerWrapper({ children }: PlayerWrapperProps): ReactElement {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      {children}
    </div>
  );
}
