import * as React from "react";
import { ImageProps } from "next/image";
import { styled } from "@mui/system";
import { Box } from "@mui/material";
import { useSkeleton } from "@tbml/shared-dependencies/react-skeletons";
import { Skeleton, Props as SkeletonProps } from "@tbml/components/Skeleton";
import {
  StoryPreviewMedia,
  StoryPreviewMediaBackgroundContainer,
  StoryPreviewMediaProps,
} from "./styles";

const ConstrainedSkeleton = styled(Skeleton)`
  max-width: 100%;
  max-height: 100%;
`;

export type Props = Omit<ImageProps, "src" | "loading" | "objectFit"> & {
  hovered: boolean;
  className?: string;
  as?: React.ComponentType<SkeletonProps> | typeof ConstrainedSkeleton;
  src?: string;
  loading?: boolean;
  width: number;
  height: number;
  allowCropping?: boolean;
  showOriginalSize?: boolean;
  onClick?: StoryPreviewMediaProps["onClick"];
};

export function StoryCardMedia({
  width,
  height,
  hovered,
  src = undefined,
  alt,
  className = undefined,
  loading = undefined,
  showOriginalSize = false,
  onClick = undefined,
  ...props
}: Props): JSX.Element | null {
  const StoryCardMediaSkeleton = React.useCallback(
    () => (
      <Box position="relative" className={className}>
        <StoryPreviewMediaBackgroundContainer height={height} loading>
          <StoryPreviewMedia
            hovered={hovered}
            height={height}
            maxHeight={height}
            as={ConstrainedSkeleton}
            alt={alt}
            showOriginalSize={showOriginalSize}
          />
        </StoryPreviewMediaBackgroundContainer>
      </Box>
    ),
    [alt, className, height, hovered, showOriginalSize]
  );

  const [imageLoaded, setImageLoaded] = React.useState<boolean>(false);
  const { withSkeleton } = useSkeleton({
    Skeleton: StoryCardMediaSkeleton,
    isLoading: loading,
  });

  if (!src) return withSkeleton(null);
  if (!loading && !src) return null;

  return withSkeleton(
    <Box position="relative" className={className}>
      <StoryPreviewMediaBackgroundContainer
        width={width}
        height={height}
        loading={!imageLoaded}
      >
        <StoryPreviewMedia
          width={width}
          height={height}
          maxHeight={height}
          hovered={hovered}
          src={src}
          alt={alt}
          onLoadingComplete={() => setImageLoaded(true)}
          onClick={onClick}
          showOriginalSize={showOriginalSize}
          {...props}
        />
      </StoryPreviewMediaBackgroundContainer>
    </Box>
  );
}
