import { Maybe } from "@tbml/api-interface/graphql";

export function fixInstagramEmbeddedLink(url: Maybe<string>): Maybe<string> {
  if (!url) {
    return url;
  }

  const toFix = new URL(url);
  if (!toFix.host.includes("instagram.com")) {
    return url;
  }

  if (toFix.pathname.includes("/p/")) {
    const fixedPath = toFix.pathname.substring(toFix.pathname.indexOf("/p/"));
    return `https://www.instagram.com${fixedPath}`;
  }
  if (toFix.pathname.includes("/reel/")) {
    const fixedPath = toFix.pathname.substring(
      toFix.pathname.indexOf("/reel/")
    );
    return `https://www.instagram.com${fixedPath}`;
  }
  return url;
}
