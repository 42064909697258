import {
  atomFamily,
  SetterOrUpdater,
  useRecoilCallback,
  useRecoilState,
} from "@tbml/shared-dependencies/recoil";

type Step = number;
const sliderStepState = atomFamily<Step, string>({
  key: "selectedSortSectionAtom",
  default: 0,
});

export const getSliderStepKey = (issueId: string, tabKey: string): string =>
  `${issueId}-${tabKey}`;

export const useSliderStepState = (
  key: string
): [Step, SetterOrUpdater<Step>] => useRecoilState(sliderStepState(key));

export const useResetSliderStep = (): ((key: string) => Promise<void>) =>
  useRecoilCallback(({ reset }) => async (key) => {
    reset(sliderStepState(key));
  });
