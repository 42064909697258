/* eslint-disable no-param-reassign */
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import throttle from "lodash/throttle";
import { Spacer } from "@tbml/components/Spacer";
import {
  FadeOutBlock,
  RelativePositioner,
  SLIDER_ITEM_WIDTH,
  StyledSlider,
} from "./styles";

type Props = {
  itemCount: number;
  itemWidth?: number;
  children: ReactElement | ReactElement[];
  activeStep: { step: number; update: "SKIP" | "UPDATE" };
  dividersInfo?: number[];
  onAllElementsVisibleChange?: (visible: boolean) => void;
  onScrollProgress?: (progress: number) => void;
};

function resetScrollStyle(el: HTMLElement) {
  el.style.scrollBehavior = "auto";
  el.style.scrollSnapType = "none";
  el.style.scrollSnapStop = "normal";
}

function restoreScrollStyle(el: HTMLElement) {
  el.style.scrollBehavior = "smooth";
  el.style.scrollSnapType = "x mandatory";
  el.style.scrollSnapStop = "always";
}

function useThrottle(cb: () => void, delay: number) {
  const cbRef = useRef(cb);
  useEffect(() => {
    cbRef.current = cb;
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    throttle(() => cbRef.current(), delay),
    [delay]
  );
}

export function Slider({
  children,
  itemCount,
  itemWidth = SLIDER_ITEM_WIDTH,
  activeStep,
  dividersInfo = [],
  onAllElementsVisibleChange = () => {},
  onScrollProgress = () => {},
  ...props
}: Props): JSX.Element {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const mouseCoords = useRef({
    startX: 0,
    scrollLeft: 0,
  });

  useEffect(() => {
    if (activeStep.update === "SKIP") {
      return;
    }
    if (wrapperRef.current) {
      const el = wrapperRef.current;
      const HALF_OF_MARGIN = 4;

      const value =
        activeStep.step * itemWidth +
        dividersInfo.filter((i) => i < activeStep.step).length * 2 +
        HALF_OF_MARGIN;
      if (value + itemWidth >= el.scrollWidth - el.clientWidth) {
        onAllElementsVisibleChange(true);
      } else {
        onAllElementsVisibleChange(false);
      }
      wrapperRef.current.scrollLeft = value;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  function update() {
    if (!wrapperRef.current || !isMobile) {
      return;
    }
    const el = wrapperRef.current;
    const scrollValue = Math.max(el.scrollLeft, 0);
    const progress = scrollValue / (el.scrollWidth - el.clientWidth);
    onScrollProgress(progress);
  }

  const updateProgress = useThrottle(update, 200);

  useEffect(() => {
    if (!isMobile) {
      return () => {};
    }
    const id = setInterval(updateProgress, 1000);
    return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RelativePositioner>
      <FadeOutBlock
        position="left"
        sx={{ opacity: activeStep.step === 0 ? 0 : 1 }}
      />
      <Spacer size="verticalS" key="slider-spacer-left" />
      <StyledSlider
        {...props}
        ref={wrapperRef}
        style={{ display: "flex", alignItems: "stretch" }}
        onTouchMove={updateProgress}
        onTouchEnd={updateProgress}
        onMouseDown={(e) => {
          e.preventDefault();
          const el = wrapperRef.current;
          if (!el) {
            return;
          }

          resetScrollStyle(el);
          const startX = e.pageX - el.offsetLeft;
          const { scrollLeft } = el;
          mouseCoords.current = { startX, scrollLeft };
          setIsScrolling(true);
        }}
        onMouseUp={() => {
          setIsScrolling(false);
          const el = wrapperRef.current;
          if (el) {
            restoreScrollStyle(el);
          }
        }}
        onMouseMove={(e: React.MouseEvent) => {
          e.preventDefault();
          if (isScrolling && wrapperRef.current) {
            const el = wrapperRef.current;
            const x = e.pageX - el.offsetLeft;
            const walkX = x - mouseCoords.current.startX;
            el.scrollLeft = mouseCoords.current.scrollLeft - walkX;
            onScrollProgress(el.scrollLeft / (el.scrollWidth - el.clientWidth));
          }
        }}
        onMouseLeave={() => {
          setIsScrolling(false);
          const el = wrapperRef.current;
          if (el) {
            restoreScrollStyle(el);
          }
        }}
      >
        {children}
      </StyledSlider>
      <Spacer size="verticalS" key="slider-spacer-right" />
      <FadeOutBlock position="right" />
    </RelativePositioner>
  );
}
