import * as React from "react";
import dynamic from "next/dynamic";
import { StoryInsideTopic } from "@tbml/api-interface/graphql";
import { Props, TopicSkeleton } from "./index";

const storyMock = {
  title: "",
  editorial: { ops: [] },
  articles: [],
  articleOrder: [],
  actionButtonIncluded: false,
  actionButtonName: "",
  actionButtonLink: "",
  image: null,
  issueId: null,
  executiveBriefingTopicId: null,
  broadcastData: null,
  assets: [],
  allAssets: [],
};
const storyDummies: StoryInsideTopic[] = [
  {
    id: "1",
    ...storyMock,
  },
  {
    id: "2",
    ...storyMock,
  },
  {
    id: "3",
    ...storyMock,
  },
  {
    id: "4",
    ...storyMock,
  },
  {
    id: "5",
    ...storyMock,
  },
];

export const DynamicTopic = dynamic<Props>(
  () => import(".").then(({ Topic }) => Topic),
  {
    loading: () => <TopicSkeleton stories={storyDummies} />,
  }
);
