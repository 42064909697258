import { styled } from "@mui/material";
import { Link } from "@tbml/components/Link";
import tokens from "@tbml/tokens";

export const TopicContainer = styled("div")`
  padding-top: ${tokens.spacing.verticalXs.value};
`;

export const StoryPreviewContainer = styled(Link)`
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;
