import * as React from "react";
import { Stack, Snackbar, Tooltip, styled } from "@mui/material";
import { EmailOutlined, ContentCopy } from "@mui/icons-material";
import { Customer } from "@tbml/api-interface/graphql";

export type Props = {
  contactInfoEmail?: Customer["contactInfoEmail"];
  contactInfoName?: Customer["contactInfoName"];
  backgroundColor?: string;
};

export const ContactInfoOverlay = styled("div", {
  shouldForwardProp: (prop) => prop !== "color",
})<{ color: string }>`
  width: fit-content;
  height: fit-content;
  background-color: ${({ color: backgroundColor }) => backgroundColor};
`;

export const ContactInfo = styled("span")`
  display: block;
`;
export default function ContactInfoRender({
  contactInfoEmail = "",
  contactInfoName = "",
  backgroundColor = "white",
}: Props): JSX.Element {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(contactInfoEmail ?? "");
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleEmailClick = () => {
    window.location.href = `mailto:${contactInfoEmail}`;
  };

  const vertical = "top";
  const horizontal = "center";

  return (
    <ContactInfoOverlay color={backgroundColor}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Stack>
          <ContactInfo>
            <b>{contactInfoName}</b>
          </ContactInfo>
          <ContactInfo>{contactInfoEmail}</ContactInfo>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Tooltip title="Send email" placement="top">
            <EmailOutlined
              sx={{ cursor: "pointer" }}
              onClick={handleEmailClick}
            />
          </Tooltip>
          <Tooltip title="Copy email to clipboard" placement="top">
            <ContentCopy sx={{ cursor: "pointer" }} onClick={handleClick} />
          </Tooltip>
          <Snackbar
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
            message="Email address copied to clipboard"
            sx={{ top: { sm: 330 }, left: { sm: 390 } }}
            anchorOrigin={{ vertical, horizontal }}
            key={vertical + horizontal}
          />
        </Stack>
      </Stack>
    </ContactInfoOverlay>
  );
}
