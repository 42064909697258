import * as React from "react";
import { Box, Button } from "@mui/material";
import { Spacer } from "@tbml/components/Spacer";
import { H3 } from "@tbml/components/Typography";
import { DeltaContent } from "@tbml/components/DeltaContent";
import { DeltaData } from "@tbml/api-interface/delta";
import AnalyticsWidgets, {
  SentimentData,
  TonalityData,
  VisibilityAndTonalityData,
} from "./AnalyticsWidgets";

export function AnalyticsSection({
  analyticsSummary,
  analyticsTitle,
  sentimentData,
  tonalityData,
  visibilityAndTonalityData,
  analyticsActionButtonIncluded,
  analyticsActionButtonName,
  analyticsActionButtonLink,
}: {
  analyticsSummary: DeltaData;
  analyticsTitle: string;
  sentimentData: SentimentData;
  tonalityData: TonalityData;
  visibilityAndTonalityData: VisibilityAndTonalityData;
  analyticsActionButtonIncluded: boolean;
  analyticsActionButtonName: string;
  analyticsActionButtonLink: string;
}): JSX.Element | null {
  return (
    <Box sx={{ paddingBottom: "20px" }}>
      <H3>{analyticsTitle}</H3>
      <Spacer size="gutterS" />
      {analyticsSummary.ops.length === 0 ? null : (
        <Box sx={{ marginBottom: "20px" }}>
          <DeltaContent
            editorialContent={analyticsSummary}
            font="longTextDefault"
          />
        </Box>
      )}
      <Spacer size="gutterM" />
      {analyticsActionButtonIncluded && (
        <Button
          variant="contained"
          target="_blank"
          component="a"
          href={`${analyticsActionButtonLink}`}
        >
          {analyticsActionButtonName}
        </Button>
      )}
      <AnalyticsWidgets
        sentimentData={sentimentData}
        tonalityData={tonalityData}
        visibilityAndTonalityData={visibilityAndTonalityData}
      />
    </Box>
  );
}
