import { Alert, Box, styled } from "@mui/material";
import tokens from "@tbml/tokens";
import { Text } from "@tbml/components/Typography";

export const SocialMediaContainer = styled("div")`
  display: flex;
`;

export const SectionText = styled(Box)`
  display: flex;
  gap: ${tokens.spacing.paddingXs.value};
`;

export const LiteBadge = styled(Text)`
  display: inline-block;
  color: inherit;
  opacity: 0.5;
  &:before {
    content: "(";
  }
  &:after {
    content: ")";
  }
`;

export const ConsentWarningPlaceholder = styled(Alert)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: ${tokens.breakpoint.xs.minWidth.value};
`;
