import { styled, MobileStepper as MuiMobileStepper } from "@mui/material";
import { SOCIAL_MEDIA_MIN_WIDTH } from "@tbml/components/SocialMediaPlatform";

export const SLIDER_ITEM_WIDTH = 300;

export const RelativePositioner = styled("div")`
  position: relative;
  overflow: hidden;
  width: 100%;
`;

export const FadeOutBlock = styled("div", {
  shouldForwardProp: (prop) => prop !== "position",
})<{
  position: "left" | "right";
}>`
  background: ${({ theme, position }) => {
    if (!theme || !theme.palette) return false;
    const {
      palette: { background },
    } = theme;
    return `linear-gradient(to ${position}, 
    transparent,
    ${background.default});`;
  }};

  position: absolute;
  right: ${({ position }) => (position === "right" ? "-1px" : "initial")};
  left: ${({ position }) => (position === "left" ? "-1px" : "initial")};
  top: 0;
  width: ${SOCIAL_MEDIA_MIN_WIDTH / 15}px;
  height: 100%;
  z-index: 1;
`;

export const StyledSlider = styled("div", {
  shouldForwardProp: (prop) => prop !== "snapAlignment",
})<{
  snapAlignment?: string;
}>`
  display: flex;
  align-items: start;
  flex-wrap: nowrap;
  height: auto;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  scroll-snap-stop: always;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

export const SliderItem = styled("div", {
  shouldForwardProp: (propName) =>
    propName !== "width" && propName !== "visible",
})<{ width: number; visible?: boolean }>`
  position: relative;
  width: ${({ width }) => `${width}px`};
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  min-height: 100%;
  display: ${({ visible }) => (visible ? "flex" : "none")};
`;

SliderItem.defaultProps = {
  visible: true,
};

export const MobileStepper = styled(MuiMobileStepper, {
  shouldForwardProp: (propName) => propName !== "itemWidth",
})<{ itemWidth: number }>`
  background-color: transparent;
  max-width: ${({ itemWidth }) => `${itemWidth}px`};
  margin: 0 auto;
`;
