import React, { useMemo } from "react";
import { Box } from "@mui/material";
import {
  SentimentDataPoint,
  TonalityDataPoint,
  VisibilityAndTonalityDataPoint,
} from "@tbml/api-interface/graphql";
import TonalityWidget from "@tbml/components/Widgets/Tonality";
import {
  getFilledDataForTonality,
  getFilledDataForVisibilityAndTonality,
} from "@tbml/components/Widgets/utils";
import { LongTextSmall } from "@tbml/components/Typography";
import { Spacer } from "@tbml/components/Spacer";
import SentimentWidget from "@tbml/components/Widgets/SentimentWidget";

export type CommonWidgetData = {
  enabled: boolean;
  caption: string;
  rangeStart: Date;
  rangeEnd: Date;
};
export type TonalityData = CommonWidgetData & {
  data: TonalityDataPoint[];
};
export type VisibilityAndTonalityData = CommonWidgetData & {
  data: VisibilityAndTonalityDataPoint[];
};
export type SentimentData = CommonWidgetData & {
  data: SentimentDataPoint[];
};

export default function AnalyticsWidgets({
  sentimentData,
  tonalityData,
  visibilityAndTonalityData,
}: {
  sentimentData: SentimentData;
  tonalityData: TonalityData;
  visibilityAndTonalityData: VisibilityAndTonalityData;
}): JSX.Element | null {
  const containsCaption = tonalityData.caption.length !== 0;
  // todo aggregate on big query level
  const sentimentDataPoints = useMemo(() => {
    const points = sentimentData.data ?? [];
    return points.reduce(
      (acc, { date, noPositive, noNeutral, noNegative }) => {
        if (date < sentimentData.rangeStart || date > sentimentData.rangeEnd) {
          return acc;
        }

        return {
          noPositive: acc.noPositive + noPositive,
          noNeutral: acc.noNeutral + noNeutral,
          noNegative: acc.noNegative + noNegative,
        };
      },
      { noPositive: 0, noNeutral: 0, noNegative: 0 }
    );
  }, [sentimentData.data, sentimentData.rangeEnd, sentimentData.rangeStart]);
  return (
    <Box
      sx={{
        boxShadow: 1,
        p: "21px",
        borderRadius: 1,
        width: "fit-content",
        maxWidth: "550px",
      }}
    >
      {tonalityData.enabled && (
        <>
          <TonalityWidget
            data={getFilledDataForTonality(
              new Date(tonalityData.rangeStart),
              new Date(tonalityData.rangeEnd),
              tonalityData.data
            )}
            title="Tonality over time"
          />
          {containsCaption ? null : <Spacer />}
          {containsCaption ? (
            <LongTextSmall sx={{ paddingTop: 4 }}>
              {tonalityData.caption}
            </LongTextSmall>
          ) : null}
        </>
      )}
      {visibilityAndTonalityData.enabled && (
        <>
          <TonalityWidget
            addVisibility
            data={getFilledDataForVisibilityAndTonality(
              new Date(visibilityAndTonalityData.rangeStart),
              new Date(visibilityAndTonalityData.rangeEnd),
              visibilityAndTonalityData.data
            )}
            title="Visibility and Tonality over time"
          />
          {containsCaption ? null : <Spacer />}
          {containsCaption ? (
            <LongTextSmall sx={{ paddingTop: 4 }}>
              {visibilityAndTonalityData.caption}
            </LongTextSmall>
          ) : null}
        </>
      )}
      {sentimentData.enabled && (
        <>
          <SentimentWidget data={sentimentDataPoints} />
          {sentimentData.caption.length ? (
            <LongTextSmall sx={{ paddingTop: 4 }}>
              {sentimentData.caption}
            </LongTextSmall>
          ) : (
            <Spacer />
          )}
        </>
      )}
    </Box>
  );
}
