import * as React from "react";
import { gql } from "graphql-request";
import parseISO from "date-fns/parseISO";
import {
  Alert,
  useMediaQuery,
  useTheme,
  Stack,
  Box,
  Button,
  Chip,
  styled,
} from "@mui/material";
import { useInView } from "react-intersection-observer";
import { useSkeleton } from "@tbml/shared-dependencies/react-skeletons";
import { useIssues } from "@tbml/hooks/useIssues";
import { H3, H4, Text } from "@tbml/components/Typography";
import { TbmlTheme } from "@tbml/components/Theme";
import { Container, PAGE_LAYOUT_PADDING } from "@tbml/components/PageLayout";
import { Spacer } from "@tbml/components/Spacer";
import {
  ArticleSortSection,
  Query,
  Customer,
  StoryInsideTopic,
} from "@tbml/api-interface/graphql";
import { Link } from "@tbml/components/Link";
import { useSocialMedia } from "@tbml/hooks/useSocialMedia";
import {
  isClientError,
  isLicenseError,
  isLicenseFreeError,
} from "@tbml/shared-dependencies/license";
import { LicenseState } from "@tbml/api-interface/licence";
import { DynamicAdditionalCoverage } from "../AdditionalCoverage/dynamic";
import { DynamicTopic } from "../Topic/dynamic";
import { SocialMediaSection } from "../SocialMediaSection";
import { LocaleTimeDisplay } from "../LocaleDate";
import { hasInboxTimeoutError } from "../AdditionalCoverage";
import { AnalyticsSection } from "../AnalyticsSection";
import ContactInfoRender from "./ContactInfoRender";

const IntersectionTrigger = styled("span")``;
export const issuePageIssueFragmentName = "IssuePageFields";
export const issuePageIssueFragment = gql`
  fragment IssuePageFields on Issue {
    id
    title
    analyticsSectionTitle
    analyticsSummary
    analyticsActionButtonIncluded
    analyticsActionButtonName
    analyticsActionButtonLink
    inboxProductIssueId
    refDate
    audioUrl
    widgetsData {
      widgetsDateRangeStart
      widgetsDateRangeEnd
      sentiment {
        enabled
        caption
        points {
          noPositive
          noNegative
          noNeutral
          date
        }
      }
      tonality {
        enabled
        caption
        points {
          noPositive
          noNegative
          noNeutral
          date
        }
      }
      visibilityAndTonality {
        enabled
        caption
        points {
          noPositive
          noNegative
          noNeutral
          date
          visibility
        }
      }
    }
    additionalCoverage {
      id
      productId
      publishedAt
      updatedAt
      sortSections {
        id
        name
      }
    }
    executiveBriefingTopics {
      id
      title
      subTitle
      stories {
        id
        title
        actionButtonIncluded
        actionButtonName
        actionButtonLink
        articles {
          id
        }
        broadcastData {
          publicUrl
          url
          duration
        }
        image {
          id
          originPath
          mediaWidth
          mediaHeight
          allowCropping
          showOriginalSize
        }
        editorial
        executiveBriefingTopicId
      }
    }
    errors
  }
`;

const emptyStory = {
  title: "",
  editorial: { ops: [] },
  articles: [],
  articleOrder: [],
  image: null,
  issueId: null,
  executiveBriefingTopicId: null,
  broadcastData: null,
  assets: [],
  allAssets: [],
  actionButtonIncluded: false,
  actionButtonName: "",
  actionButtonLink: "",
};

export function IssueSkeleton(): JSX.Element {
  const stories = React.useMemo<StoryInsideTopic[]>(
    () => [
      {
        id: "1",
        ...emptyStory,
      },
      {
        id: "2",
        ...emptyStory,
      },
      {
        id: "3",
        ...emptyStory,
      },
      {
        id: "4",
        ...emptyStory,
      },
      {
        id: "5",
        ...emptyStory,
      },
    ],
    []
  );

  return (
    <div aria-busy title="Loading issue...">
      <TbmlTheme variant="regular">
        <Container>
          <DynamicTopic title="" subtitle="" stories={stories} />
        </Container>
      </TbmlTheme>
      <TbmlTheme variant="alt">
        <Container>
          <DynamicTopic title="" subtitle="" stories={stories} />
        </Container>
      </TbmlTheme>
    </div>
  );
}

const getAddBorder = (index: number, isSmallerThanMd: boolean) => {
  if (isSmallerThanMd) return undefined;
  return index % 2 ? "right" : "left";
};

export type Props = {
  hiddenSortSectionsForAddcov: ArticleSortSection[];
  id: string;
  defaultIssueId?: string | null;
  inboxSocialMediaProductId?: string | null;
  contactInfoIncluded?: Customer["contactInfoIncluded"];
  contactInfoEmail?: Customer["contactInfoEmail"];
  contactInfoName?: Customer["contactInfoName"];
  language?: string;
};

export function Issue({
  hiddenSortSectionsForAddcov,
  id,
  defaultIssueId = undefined,
  inboxSocialMediaProductId = undefined,
  contactInfoIncluded = false,
  contactInfoEmail = "",
  contactInfoName = "",
  language = "en",
}: Props): JSX.Element | null {
  const { query } = useIssues();
  const { data, status, error } = query({
    filter: { ids: [id] },
    fragment: issuePageIssueFragment,
    fragmentName: issuePageIssueFragmentName,
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down("md"));

  const { ref: socialMediaSectionRef, inView: socialMediaSectionInView } =
    useInView({
      triggerOnce: true,
    });
  const { ref: topicRef, inView: topicInView } = useInView({
    triggerOnce: true,
  });

  const availableInboxSocialMediaId =
    topicInView || socialMediaSectionInView ? inboxSocialMediaProductId : null;

  const licenseState: LicenseState = React.useMemo(() => {
    if (isLicenseError(error)) return "archived";
    if (isLicenseFreeError(error)) return "license-free";
    return "licensed";
  }, [error]);

  const issue = React.useMemo(() => {
    if (isClientError(error) && licenseState !== "licensed") {
      const { getIssues } = error.response.data as Query;
      const [licensedIssue] = getIssues?.issues ?? [];
      return licensedIssue;
    }
    return data?.issues.find(({ id: issueId }) => issueId === id);
  }, [error, data?.issues, id, licenseState]);

  const shouldFetchSocialMediaArticles =
    !!issue && !!availableInboxSocialMediaId;
  const { data: socialMediaArticles, status: socialMediaArticleStatus } =
    useSocialMedia().query({
      inboxSocialMediaId: availableInboxSocialMediaId || "",
      issueIds: issue ? [issue.id] : [],
      enabled: shouldFetchSocialMediaArticles,
    });

  const filteredTopics = React.useMemo(
    () =>
      (issue?.executiveBriefingTopics ?? []).filter(
        ({ stories }) => (stories ?? []).length > 0
      ),
    [issue?.executiveBriefingTopics]
  );

  const socialMediaThemeVariant = React.useMemo(() => {
    if (!issue) return "regular";

    const isAltAfterTopics = filteredTopics.length % 2 === 0;
    if (issue.additionalCoverage) {
      return isAltAfterTopics ? "alt" : "regular";
    }
    return isAltAfterTopics ? "regular" : "alt";
  }, [issue, filteredTopics]);

  const { withSkeleton } = useSkeleton({
    isLoading: status === "pending",
    Skeleton: IssueSkeleton,
  });

  if (status === "pending") {
    return withSkeleton(null);
  }

  if (status === "error" && licenseState === "licensed") {
    return <Text>There was an error when trying to get the issue.</Text>;
  }

  if (!issue) {
    return (
      <>
        <Spacer size="paddingM" />
        <H3 color="textSecondary">Issue not found</H3>
        <Spacer size="paddingM" />
        <Text>
          The issue you are looking for is not available. It might have been
          deleted or is not available anymore.
        </Text>
        <Spacer size="paddingM" />
      </>
    );
  }

  const showSocialMediaSection =
    (socialMediaArticleStatus === "pending" &&
      shouldFetchSocialMediaArticles) ||
    (socialMediaArticleStatus === "success" && !!socialMediaArticles?.length);

  const analyticsOrTTsDisplayed =
    (issue.analyticsSectionTitle && issue.analyticsSummary) || issue.audioUrl;

  return (
    <div title={issue.title ?? ""}>
      {defaultIssueId && defaultIssueId !== id && (
        <>
          <Spacer size="paddingM" />
          <Alert
            severity="info"
            action={
              <Link href={`/${defaultIssueId}`}>
                <Button color="inherit" size="small">
                  View
                </Button>
              </Link>
            }
          >
            There is a newer issue available
          </Alert>
          <Spacer size="paddingM" />
        </>
      )}
      <Container
        aria-label="Issue Container"
        sx={
          analyticsOrTTsDisplayed
            ? {
                backgroundColor: theme.palette.background.paper,
              }
            : {}
        }
      >
        <Spacer size="paddingM" />
        <Stack
          direction={isMobile ? "column-reverse" : "row"}
          alignItems={isMobile ? "flex-end" : "center"}
          justifyContent="space-between"
        >
          <div>
            {contactInfoIncluded && (
              <ContactInfoRender
                contactInfoEmail={contactInfoEmail}
                contactInfoName={contactInfoName}
                backgroundColor="transparent"
              />
            )}
          </div>
          <H3 color="textSecondary">
            <Stack direction="row">
              <LocaleTimeDisplay date={parseISO(issue.refDate)} />
              {defaultIssueId === id && (
                <>
                  <Spacer size="paddingS" />
                  <Text paragraph={false}>
                    <Chip label="latest" color="primary" />
                  </Text>
                </>
              )}
            </Stack>
          </H3>
        </Stack>
      </Container>
      {issue.analyticsSectionTitle && issue.analyticsSummary ? (
        <Container
          position="static"
          sx={{
            backgroundColor: theme.palette.background.paper,
            paddingTop: 4,
          }}
        >
          <AnalyticsSection
            analyticsSummary={issue.analyticsSummary}
            analyticsTitle={issue.analyticsSectionTitle}
            sentimentData={{
              data: issue.widgetsData?.sentiment?.points ?? [],
              caption: issue.widgetsData?.sentiment?.caption ?? "",
              enabled: issue.widgetsData?.sentiment?.enabled ?? false,
              rangeStart:
                issue.widgetsData?.widgetsDateRangeStart ?? new Date(),
              rangeEnd: issue.widgetsData?.widgetsDateRangeEnd ?? new Date(),
            }}
            tonalityData={{
              data: issue.widgetsData?.tonality?.points ?? [],
              caption: issue.widgetsData?.tonality?.caption ?? "",
              enabled: issue.widgetsData?.tonality?.enabled ?? false,
              rangeStart:
                issue.widgetsData?.widgetsDateRangeStart ?? new Date(),
              rangeEnd: issue.widgetsData?.widgetsDateRangeEnd ?? new Date(),
            }}
            visibilityAndTonalityData={{
              data: issue.widgetsData?.visibilityAndTonality?.points ?? [],
              caption: issue.widgetsData?.visibilityAndTonality?.caption ?? "",
              enabled:
                issue.widgetsData?.visibilityAndTonality?.enabled ?? false,
              rangeStart:
                issue.widgetsData?.widgetsDateRangeStart ?? new Date(),
              rangeEnd: issue.widgetsData?.widgetsDateRangeEnd ?? new Date(),
            }}
            analyticsActionButtonIncluded={
              issue.analyticsActionButtonIncluded ?? false
            }
            analyticsActionButtonName={issue.analyticsActionButtonName ?? ""}
            analyticsActionButtonLink={issue.analyticsActionButtonLink ?? ""}
          />
        </Container>
      ) : null}
      {issue.audioUrl ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          paddingX={PAGE_LAYOUT_PADDING}
          paddingTop={PAGE_LAYOUT_PADDING}
          paddingBottom={PAGE_LAYOUT_PADDING}
          sx={{
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <H4 sx={{ width: "30%" }}>
            {language === "de" ? "Ausgabe anhören:" : "Listen to the issue:"}
          </H4>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <audio
            src={issue.audioUrl}
            controls
            controlsList="nodownload"
            style={{ width: "100%" }}
          />
        </Box>
      ) : null}
      {filteredTopics.map(
        ({ stories, id: topicId, title, subTitle }, index) => (
          <TbmlTheme
            key={topicId}
            variant={index % 2 === 0 ? "regular" : "alt"}
          >
            <Container
              aria-label={`Topic Container ${index}`}
              addBorder={getAddBorder(index, isSmallerThanMd)}
              position="static"
            >
              <DynamicTopic
                title={title}
                subtitle={subTitle ?? undefined}
                stories={stories ?? []}
              />
              <IntersectionTrigger ref={index === 0 ? topicRef : null} />
            </Container>
          </TbmlTheme>
        )
      )}
      {(issue.additionalCoverage || hasInboxTimeoutError(issue.errors)) && (
        <TbmlTheme
          variant={filteredTopics.length % 2 === 0 ? "regular" : "alt"}
        >
          <Container position="static">
            <DynamicAdditionalCoverage
              hasScrollSection
              hiddenSortSectionsForAddcov={hiddenSortSectionsForAddcov}
              issueId={issue.id}
              hasInboxTimeout={hasInboxTimeoutError(issue.errors)}
            />
            <Spacer size="verticalL" />
          </Container>
        </TbmlTheme>
      )}
      <Box ref={socialMediaSectionRef}>
        {showSocialMediaSection && (
          <TbmlTheme variant={socialMediaThemeVariant}>
            <Container position="static">
              <Spacer size="verticalXxl" />
              <SocialMediaSection
                inboxSocialMediaId={availableInboxSocialMediaId}
                issueId={issue.id}
              />
              <Spacer size="verticalL" />
            </Container>
          </TbmlTheme>
        )}
      </Box>
    </div>
  );
}
