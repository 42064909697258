import { Box, Skeleton, styled } from "@mui/material";
import {
  InstagramEmbed,
  TwitterEmbed,
  YouTubeEmbed,
} from "react-social-media-embed";
import tokens from "@tbml/tokens";

export const SOCIAL_MEDIA_MIN_WIDTH = 350;
export const SOCIAL_MEDIA_MIN_HEIGHT = 450;

const BaseSkeleton = styled(Skeleton)`
  transform: none;
  border-radius: 0;
`;

export const YouTubeArticleSkeleton = styled(BaseSkeleton)`
  min-width: ${SOCIAL_MEDIA_MIN_WIDTH}px;
  width: 100%;
  aspect-ratio: 16 / 9;
`;
YouTubeArticleSkeleton.defaultProps = {
  "aria-label": "Youtube skeleton",
};

export const PortraitFormatArticleSkeleton = styled(BaseSkeleton)`
  width: 100%;
  min-height: ${SOCIAL_MEDIA_MIN_HEIGHT}px;
  min-width: ${SOCIAL_MEDIA_MIN_WIDTH}px;
`;

export const ArticleError = styled(Box)`
  display: grid;
  place-items: center;
  min-width: ${SOCIAL_MEDIA_MIN_WIDTH}px;
  min-height: ${SOCIAL_MEDIA_MIN_HEIGHT}px;
  border: 1px solid ${tokens.color.divider.value};
`;

export const YoutubeArticleError = styled(ArticleError)`
  min-height: initial;
  aspect-ratio: 16 / 9;
`;

export const LinkedInCustomIFrame = styled("iframe")`
  min-width: ${SOCIAL_MEDIA_MIN_WIDTH}px;
`;

export const TwitterCustomEmbed = styled(TwitterEmbed, {
  shouldForwardProp: (prop) => prop !== "minWidth",
})<{ minWidth?: number }>`
  min-width: ${({ minWidth }) => minWidth ?? "unset"}px;
`;

export const InstagramCustomEmbed = styled(InstagramEmbed, {
  shouldForwardProp: (prop) => prop !== "minWidth",
})<{ minWidth?: number }>`
  min-width: ${({ minWidth }) => minWidth ?? "unset"}px;
`;

export const FacebookCustomEmbed = styled("div", {
  shouldForwardProp: (prop) => prop !== "hide",
})<{ hide: boolean }>`
  height: ${({ hide }) => (hide ? 0 : "auto")};
`;

export const ActionContainer = styled("div")`
  display: flex;
  justify-content: flex-end;
  gap: ${tokens.spacing.verticalXs.value};
`;

export const YouTubeCustomEmbed = styled(YouTubeEmbed, {
  shouldForwardProp: (prop) => prop !== "minWidth",
})<{ minWidth: number }>`
  display: flex;
  min-width: ${({ minWidth }) => minWidth ?? "unset"}px;
  aspect-ratio: 16 / 9;
  > div {
    width: 100%;
    height: 100%;
    .youtube-iframe {
      height: 100%;
    }
  }
`;

export const HiddenColorLayer = styled("svg")`
  width: 0;
  height: 0;
  position: absolute;
`;
