import { Fab, styled } from "@mui/material";
import { Link } from "@tbml/components/Link";
import { Text } from "@tbml/components/Typography";
import tokens from "@tbml/tokens";

export const VerticallyCentered = styled("div")`
  display: flex;
  align-items: center;
  color: ${tokens.color.textSecondary.value};
`;

export const Flex = styled("div")`
  display: flex;
`;

export const HeightExpandingLink = styled(Link)`
  display: flex;
  height: 100%;
`;

export const HeightExpander = styled("div")`
  display: flex;
  height: 100%;
`;

export const SectionText = styled("div")`
  display: flex;
  gap: ${tokens.spacing.paddingXs.value};
`;

export const LiteBadge = styled(Text)`
  display: inline-block;
  opacity: 0.5;
  &:before {
    content: "(";
  }
  &:after {
    content: ")";
  }
`;

export const SliderFab = styled(Fab)`
  flex-shrink: 0;
  align-self: center;
`;
