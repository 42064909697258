import React, { useEffect, useRef, useState } from "react";
import { Dialog, IconButton, Stack, Box } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import ReactPlayer from "react-player";
import { PlayerWrapper, ResponsiveReactPlayer } from "./styles";

type Props = {
  startingTime: number;
  isOpen: boolean;
  src: string;
  isAudio: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onClose?: (playbackTime: number) => void;
};

export function BroadcastMediaModal({
  startingTime,
  isOpen,
  src,
  isAudio,
  setIsOpen,
  onClose = () => {},
}: Props): JSX.Element {
  const handleClose = () => setIsOpen(false);
  const playerRef = useRef<ReactPlayer>(null);
  const [playerInitialized, setPlayerInitialized] = useState(false);

  const closeOverlay = () => {
    handleClose();
    let playbackTime = 0;
    if (playerRef.current) {
      playbackTime = playerRef.current.getCurrentTime();
    }
    onClose(playbackTime);
  };

  useEffect(() => {
    setPlayerInitialized(false);
  }, [startingTime]);

  return (
    <Dialog
      open={isOpen}
      onClose={(event: React.BaseSyntheticEvent) => {
        event.preventDefault();
        closeOverlay();
      }}
      aria-label="broadcast-media-modal"
      maxWidth={false}
    >
      <Box
        sx={{
          boxShadow: 24,
          p: 1,
        }}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <Stack>
          <Box
            sx={{
              flex: "0 0 auto",
              alignItems: "center",
              display: "flex",
              minWidth: 300,
              minHeight: 200,
            }}
          >
            <ResponsiveReactPlayer
              ref={playerRef}
              onReady={() => {
                if (playerRef.current && !playerInitialized) {
                  setPlayerInitialized(true);
                  playerRef.current.seekTo(startingTime, "seconds");
                }
              }}
              url={src}
              width="100%"
              height="100%"
              wrapper={!isAudio ? PlayerWrapper : undefined}
              controls
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                  },
                },
              }}
              ratio={isAudio ? 21 / 3 : 16 / 9}
            />
          </Box>
          <IconButton
            aria-label="close"
            onClick={closeOverlay}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </Box>
    </Dialog>
  );
}
