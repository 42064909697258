export type SocialMediaPlatforms = {
  twitter: SocialMediaPlatform;
  facebook: SocialMediaPlatform;
  youtube: SocialMediaPlatform;
  instagram: SocialMediaPlatform;
  tiktok: SocialMediaPlatform;
  linkedIn: SocialMediaPlatform;
};

export type SocialMediaInboxMedium = {
  twitter: PlatformData;
  facebookPost: PlatformData;
  facebookVideo: PlatformData;
  youtube: PlatformData;
  instagram: PlatformData;
  tiktok: PlatformData;
  linkedIn: PlatformData;
};

export type PlatformData = {
  record: RecordData;
  provider: {
    name: string;
    url: string;
  };
  embedCodePattern?: RegExp | undefined;
  urlPattern: RegExp;
};

export type RecordData = {
  mediaId: number;
  mediaName: string;
  mediaForm: ArticleMediaForm;
  mediaSubForm: ArticleMediaSubForm;
};

export type SocialMediaPlatform = {
  label: string;
  mediaIds: number[];
  embedWidth?: number;
};

// eslint-disable-next-line no-restricted-syntax
enum ArticleMediaForm {
  SocialMedia = "SOCIAL_MEDIA",
}
// eslint-disable-next-line no-restricted-syntax
enum ArticleMediaSubForm {
  SocialNetworks = "SOCIAL_NETWORKS",
}

export const socialMediaInboxMedium: SocialMediaInboxMedium = {
  twitter: {
    record: {
      mediaId: 46339,
      mediaName: "Twitter",
      mediaForm: ArticleMediaForm.SocialMedia,
      mediaSubForm: ArticleMediaSubForm.SocialNetworks,
    },
    provider: {
      name: "Twitter",
      url: "https://www.twitter.com",
    },
    urlPattern: /https?:\/\/(www\.)?(twitter|x)\.com\/\w+\/status\/\d+/,
  },
  facebookPost: {
    record: {
      mediaId: 45660,
      mediaName: "Facebook Post",
      mediaForm: ArticleMediaForm.SocialMedia,
      mediaSubForm: ArticleMediaSubForm.SocialNetworks,
    },
    provider: {
      name: "Facebook",
      url: "https://www.facebook.com",
    },
    urlPattern:
      /^https:\/\/www\.facebook\.com\/(photo(\.php|s)|permalink\.php|media|questions|notes|[^/]+\/(activity|posts))[/?].*$/,
  },
  facebookVideo: {
    record: {
      mediaId: process.env.NODE_ENV === "production" ? 1593723 : 1589931, // TODO: After Inbox DB Sync change ID to production one
      mediaName: "Facebook Video",
      mediaForm: ArticleMediaForm.SocialMedia,
      mediaSubForm: ArticleMediaSubForm.SocialNetworks,
    },
    provider: {
      name: "Facebook",
      url: "https://www.facebook.com",
    },
    urlPattern:
      /^https:\/\/www\.facebook\.com\/(([^/?].+\/)?video(s|\.php)[/?].*$)|(permalink\.php\?)/,
  },
  instagram: {
    record: {
      mediaId: 231521,
      mediaName: "Instagram",
      mediaForm: ArticleMediaForm.SocialMedia,
      mediaSubForm: ArticleMediaSubForm.SocialNetworks,
    },
    provider: {
      name: "Instagram",
      url: "https://www.instagram.com",
    },
    urlPattern: /https?:\/\/(www.)?instagram.com\//,
  },
  youtube: {
    record: {
      mediaId: 1068208,
      mediaName: "YouTube",
      mediaForm: ArticleMediaForm.SocialMedia,
      mediaSubForm: ArticleMediaSubForm.SocialNetworks,
    },
    provider: {
      name: "YouTube",
      url: "https://www.youtube.com",
    },
    urlPattern:
      /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/,
  },
  tiktok: {
    record: {
      mediaId: 1555035,
      mediaName: "TikTok",
      mediaForm: ArticleMediaForm.SocialMedia,
      mediaSubForm: ArticleMediaSubForm.SocialNetworks,
    },
    provider: {
      name: "TikTok",
      url: "https://www.tiktok.com",
    },
    urlPattern: /https?:\/\/(www.)?tiktok.com\//,
  },
  linkedIn: {
    record: {
      mediaId: 45998,
      mediaName: "LinkedIn",
      mediaForm: ArticleMediaForm.SocialMedia,
      mediaSubForm: ArticleMediaSubForm.SocialNetworks,
    },
    provider: {
      name: "LinkedIn",
      url: "https://www.linkedin.com",
    },
    urlPattern: /^https:\/\/www.linkedin.com\/.*/,
    embedCodePattern:
      /<iframe.*?src="https:\/\/www\.linkedin\.com\/embed\/(.*)<\/iframe>/,
  },
};

export const socialMediaPlatforms: SocialMediaPlatforms = {
  linkedIn: {
    label: "LinkedIn",
    mediaIds: [socialMediaInboxMedium.linkedIn.record.mediaId],
  },
  twitter: {
    label: "X",
    mediaIds: [socialMediaInboxMedium.twitter.record.mediaId],
  },
  facebook: {
    label: "Facebook",
    mediaIds: [
      socialMediaInboxMedium.facebookPost.record.mediaId,
      socialMediaInboxMedium.facebookVideo.record.mediaId,
    ],
  },
  youtube: {
    label: "YouTube",
    mediaIds: [socialMediaInboxMedium.youtube.record.mediaId],
  },
  instagram: {
    label: "Instagram",
    mediaIds: [socialMediaInboxMedium.instagram.record.mediaId],
  },
  tiktok: {
    label: "TikTok",
    mediaIds: [socialMediaInboxMedium.tiktok.record.mediaId],
    embedWidth: 325,
  },
};

export const mediaIds = Object.values(socialMediaPlatforms)
  .map((platform) => platform.mediaIds)
  .flat(1);
