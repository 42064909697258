import { gql } from "graphql-request";
import { QueryObserverResult } from "@tanstack/react-query";
import { mediaIds } from "@tbml/api-interface/socialMedia";
import { ArticleStatus } from "@tbml/api-interface/graphql";
import { Article, useArticles } from "../useArticles";

const UseSocialMediaArticlesFieldsFragment = gql`
  fragment UseSocialMediaArticlesFieldsFragment on Article {
    processedAt
    deeplink
    mediaId
    id
    productId
    oEmbed {
      html
    }
  }
`;

type Fragment = {
  fragment: string;
  fragmentName: string;
};

type SocialMediaQueryParams = {
  inboxSocialMediaId: string;
  fragmentParts?: Fragment;
  issueIds?: string[];
  enabled?: boolean;
};

type SocialMediaHookResult = {
  query: ({
    fragmentParts,
    issueIds,
    enabled,
  }: SocialMediaQueryParams) => QueryObserverResult<Article[], Error>;
};

export const useSocialMedia = (): SocialMediaHookResult => {
  const { query: articleQuery } = useArticles();
  const query = ({
    inboxSocialMediaId,
    fragmentParts = {
      fragment: UseSocialMediaArticlesFieldsFragment,
      fragmentName: "UseSocialMediaArticlesFieldsFragment",
    },
    issueIds = undefined,
    enabled = true,
  }: SocialMediaQueryParams) =>
    articleQuery({
      filter: {
        productIds: [inboxSocialMediaId],
        issueIds,
        mediaIds,
        status: [ArticleStatus.Proposed, ArticleStatus.Confirmed],
      },
      ...fragmentParts,
      enabled,
    });

  return {
    query,
  };
};
