import { styled } from "@mui/material";
import { DeltaContent } from "@tbml/components/DeltaContent";
import tokens from "@tbml/tokens";
import { H5 } from "@tbml/components/Typography";

export const ContentWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const ARTICLE_SLIDE_LINE_WIDTH = "32px";
export const ARTICLE_SLIDE_LINE_HEIGHT = "5px";

export const ArticleSlideLine = styled("div")`
  width: ${ARTICLE_SLIDE_LINE_WIDTH};
  height: ${ARTICLE_SLIDE_LINE_HEIGHT};
  background: ${({ theme }) => theme.palette.primary.main};
  flex-shrink: 0;
`;

export const ArticleSlideWrapper = styled("div")<{ isSelected?: boolean }>`
  border: ${({ isSelected, theme }) =>
    isSelected && `2px solid ${theme.palette.primary.main}`};
  border-radius: 4px;
  background: ${({ theme }) => theme.palette.background.paper};
  padding: ${tokens.spacing.verticalXl.value};
  display: flex;
  flex-direction: column;
  transition: ${({ theme }) => theme.transitions.duration.standard}ms;
  font-size: ${tokens.font.shortTextSmall.fontSize.value};
`;

export const ArticleSlideBody = styled(DeltaContent)`
  color: ${({ theme }) => {
    if (!theme || !theme.palette) return false;
    const {
      palette: {
        text: { secondary },
      },
    } = theme;
    return secondary;
  }};
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
`;

export const HoverH5 = styled(H5, {
  shouldForwardProp: (propName) => propName !== "hover",
})<{ hover?: boolean }>`
  color: ${({ hover, theme }) =>
    hover ? theme.palette.primary.main : undefined};
  transition: color ${({ theme }) => theme.transitions.duration.standard}ms;
`;
