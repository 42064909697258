import * as React from "react";
import {
  Facebook,
  Instagram,
  YouTube,
  LinkedIn,
  X as XIcon,
} from "@mui/icons-material";
import HelpIcon from "@mui/icons-material/Help";
import { Icon } from "@mui/material";
import {
  SocialMediaPlatform,
  socialMediaPlatforms,
} from "@tbml/api-interface/socialMedia";
import tokens from "@tbml/tokens";
import { HiddenColorLayer } from "./styles";

type SocialMediaIconProps = {
  platform: SocialMediaPlatform;
  size?: "small" | "large" | "inherit" | "medium";
  color?: string;
};

export function getBrandColorByPlatform(platform: SocialMediaPlatform): string {
  switch (platform) {
    case socialMediaPlatforms.twitter:
      return "#1DA1F2";
    case socialMediaPlatforms.facebook:
      return "#3B5998";
    case socialMediaPlatforms.youtube:
      return "#FF0000";
    case socialMediaPlatforms.tiktok:
      return "#000000";
    case socialMediaPlatforms.linkedIn:
      return "#0077B5";
    case socialMediaPlatforms.instagram:
      return "#FF0069";
    default:
      return tokens.color.brandMain.value;
  }
}

export function SocialMediaIcon({
  platform,
  size = "large",
  color = undefined,
}: SocialMediaIconProps): JSX.Element {
  const htmlColor = color ?? getBrandColorByPlatform(platform);
  switch (platform) {
    case socialMediaPlatforms.twitter:
      return <XIcon fontSize={size} htmlColor={htmlColor} />;
    case socialMediaPlatforms.facebook:
      return <Facebook fontSize={size} htmlColor={htmlColor} />;
    case socialMediaPlatforms.youtube:
      return <YouTube fontSize={size} htmlColor={htmlColor} />;
    case socialMediaPlatforms.linkedIn:
      return <LinkedIn fontSize={size} htmlColor={htmlColor} />;
    case socialMediaPlatforms.tiktok:
      return (
        <Icon fontSize={size}>
          <svg
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 60"
          >
            <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
          </svg>
        </Icon>
      );
    case socialMediaPlatforms.instagram:
      if (color) {
        return <Instagram fontSize={size} htmlColor={htmlColor} />;
      }
      return (
        <>
          <Instagram
            fontSize={size}
            htmlColor={htmlColor}
            sx={{ fill: "url(#ig_brand)" }}
          />
          <HiddenColorLayer>
            <radialGradient id="ig_brand" r="150%" cx="30%" cy="107%">
              <stop stopColor="#fdf497" offset="0" />
              <stop stopColor="#fdf497" offset="0.05" />
              <stop stopColor="#fd5949" offset="0.45" />
              <stop stopColor="#d6249f" offset="0.6" />
              <stop stopColor="#285AEB" offset="0.9" />
            </radialGradient>
          </HiddenColorLayer>
        </>
      );
    default:
      return <HelpIcon fontSize="large" />;
  }
}
