import * as React from "react";
import { TikTokEmbed, TikTokEmbedProps } from "react-social-media-embed";
import { Box, Link, Typography } from "@mui/material";
import { useSkeleton } from "@tbml/shared-dependencies/react-skeletons";
import { socialMediaInboxMedium } from "@tbml/api-interface/socialMedia";
import { ArticleError, PortraitFormatArticleSkeleton } from "../styles";

export const TIKTOK_DEFAULT_DIMENSIONS: { width: number; height: number } = {
  width: 350,
  height: 575,
};

export type TikTokArticleProps = {
  deeplink: string;
  loading?: boolean;
} & Omit<TikTokEmbedProps, "url" | "width">;

function TikTokArticleSkeleton() {
  return (
    <PortraitFormatArticleSkeleton
      aria-label="TikTok skeleton"
      {...TIKTOK_DEFAULT_DIMENSIONS}
    />
  );
}

export function TikTokArticle({
  deeplink,
  loading = false,
  ...props
}: TikTokArticleProps & {
  deeplink: string;
}): JSX.Element {
  const { urlPattern } = socialMediaInboxMedium.tiktok;
  const { withSkeleton } = useSkeleton({
    Skeleton: TikTokArticleSkeleton,
    isLoading: loading,
  });

  if (!urlPattern.test(deeplink)) {
    return (
      <ArticleError {...TIKTOK_DEFAULT_DIMENSIONS}>
        <Box>
          <Typography component="div">
            Oops something went wrong here
          </Typography>
          <Link href={deeplink}>Click here to open the article</Link>
        </Box>
      </ArticleError>
    );
  }

  return withSkeleton(
    <TikTokEmbed
      {...props}
      aria-label={`TikTok article ${deeplink}`}
      width={TIKTOK_DEFAULT_DIMENSIONS.width}
      embedPlaceholder={
        <PortraitFormatArticleSkeleton
          {...TIKTOK_DEFAULT_DIMENSIONS}
          aria-label={`TikTok skeleton ${deeplink}`}
        />
      }
      url={deeplink}
    />
  );
}
