import * as React from "react";
import { enUS } from "date-fns/locale";
import { setDefaultOptions } from "date-fns";
import format from "date-fns/format";

type LocaleTimeDisplayProps = {
  date: Date;
};

export function LocaleTimeDisplay({
  date,
}: LocaleTimeDisplayProps): JSX.Element {
  const [locale, setLocale] = React.useState<Locale>(enUS);

  React.useEffect(() => {
    const importLocaleFile = async () => {
      const lang = window.navigator.language.slice(0, 2);

      if (lang === "en") return;

      const localeToSet = await import(
        /* webpackMode: "lazy", webpackChunkName: "df-[index]", webpackExclude: /_lib/ */
        `date-fns/locale/${lang}/index.js`
      );
      setDefaultOptions({ locale: localeToSet.default });
      setLocale(localeToSet.default);
    };

    importLocaleFile();
  }, [locale]);
  return (
    <span>
      {format(date, "PPP", {
        locale,
      })}
    </span>
  );
}
