import React, { useMemo } from "react";
import { Alert, AlertTitle } from "@mui/material";
import {
  SignalWifiOff as SignalWifiOffIcon,
  WarningAmber as WarningAmberIcon,
} from "@mui/icons-material";
import { gql } from "graphql-request";
import { useSkeleton } from "@tbml/shared-dependencies/react-skeletons";
import { useIssues } from "@tbml/hooks/useIssues";
import { Skeleton } from "@tbml/components/Skeleton";
import { Spacer } from "@tbml/components/Spacer";
import { TextSmall, H3 } from "@tbml/components/Typography";
import { ArticleSortSection, Maybe, Query } from "@tbml/api-interface/graphql";
import {
  isClientError,
  isLicenseError,
  isLicenseFreeError,
} from "@tbml/shared-dependencies/license";
import { LicenseState } from "@tbml/api-interface/licence";
import useScrollSection from "@tbml/components/ScrollSections/useScrollSection";
import { useSelectedSortSectionIdState } from "../../state/selectedSortSection";
import { useHashNavigation } from "../../hooks/useHashNavigation";
import { AdditionalCoverageArticles } from "./AdditionalCoverageArticles";
import { Flex, VerticallyCentered } from "./styles";

export type Props = {
  issueId: string;
  hasInboxTimeout?: boolean;
  hasScrollSection?: boolean;
  hiddenSortSectionsForAddcov: ArticleSortSection[];
};

export const hasInboxTimeoutError = (errors: Maybe<Maybe<string>[]>): boolean =>
  (errors ?? []).some(
    (message) =>
      message &&
      message.includes(
        "Could not fetch data for Additional Coverage section: Request to Inbox-API failed (The user aborted a request.)."
      )
  );

export const issueAddCovFragmentName = "IssueAddCovFields";
export const issueAddCovFragment = gql`
  fragment IssueAddCovFields on Issue {
    inboxProductIssueId
    additionalCoverage {
      id
      productId
      publishedAt
      updatedAt
      sortSections {
        id
        name
        count
      }
    }
    errors
  }
`;

export function AdditionalCoverageSkeleton(): JSX.Element {
  return (
    <div>
      <Skeleton width="220px" forText="h3" />
      <Spacer size="gutterS" />
      <Flex>
        <Skeleton width="220px" height="48px" />
        <Spacer size="horizontalS" />
        <Skeleton width="220px" height="48px" />
      </Flex>
      <Spacer size="verticalL" />
      <AdditionalCoverageArticles
        inboxProductIssueId={undefined}
        sortSections={[]}
      />
    </div>
  );
}

export function AdditionalCoverage({
  issueId,
  hasInboxTimeout = false,
  hasScrollSection = false,
  hiddenSortSectionsForAddcov,
  ...props
}: Props): JSX.Element {
  const { query: queryIssues } = useIssues({
    fragment: issueAddCovFragment,
    fragmentName: issueAddCovFragmentName,
  });
  const shouldFetchIssues = !!issueId;
  const {
    data: issues,
    status,
    error,
  } = queryIssues({
    filter: { ids: [issueId] },
    enabled: shouldFetchIssues,
  });

  const licenseState: LicenseState = React.useMemo(() => {
    if (isLicenseError(error)) return "archived";
    if (isLicenseFreeError(error)) return "license-free";
    return "licensed";
  }, [error]);

  const issue = React.useMemo(() => {
    if (isClientError(error) && licenseState !== "licensed") {
      const { getIssues } = error.response.data as Query;
      const [licensedIssue] = getIssues?.issues ?? [];
      return licensedIssue;
    }
    const [currentIssue] = issues?.issues ?? [];
    return currentIssue;
  }, [error, issues?.issues, licenseState]);

  const fetchedSortSections = useMemo(() => {
    const hiddenSortSectionIds = hiddenSortSectionsForAddcov.map(
      ({ id }) => id
    );
    return issue?.additionalCoverage?.sortSections
      .filter(({ id }) => !hiddenSortSectionIds.includes(id))
      .filter(({ count }) => !!count);
  }, [hiddenSortSectionsForAddcov, issue?.additionalCoverage?.sortSections]);

  const [selectedSortSectionId] = useSelectedSortSectionIdState(issueId);
  const displayedSortSectionId =
    selectedSortSectionId || fetchedSortSections?.[0]?.id;

  const displayedSortSection = useMemo(() => {
    if (!displayedSortSectionId || !fetchedSortSections) return undefined;
    return fetchedSortSections.find(({ id }) => id === displayedSortSectionId);
  }, [fetchedSortSections, displayedSortSectionId]);

  const hasNoInboxData = React.useMemo(() => {
    if (!issue) return hasInboxTimeout;
    return hasInboxTimeoutError(issue.errors);
  }, [hasInboxTimeout, issue]);

  const isLoading = shouldFetchIssues && status === "pending";

  const { withSkeleton } = useSkeleton({
    isLoading: (isParentSkeletonLoading) =>
      isParentSkeletonLoading || isLoading || !fetchedSortSections,
    Skeleton: AdditionalCoverageSkeleton,
  });

  const additionalCoverageError = React.useMemo(
    () =>
      issue?.errors?.find((issueError) =>
        issueError?.startsWith(
          "Could not fetch data for Additional Coverage section"
        )
      ),
    [issue]
  );
  useHashNavigation("addcov", [!hasNoInboxData]);
  const scrollSectionProps = useScrollSection();

  if (hasNoInboxData) {
    return (
      <div {...props}>
        <Spacer size="verticalXxl" />
        <H3>Additional Coverage</H3>
        <Spacer size="gutterS" />
        <VerticallyCentered>
          <WarningAmberIcon aria-label="Inbox timeout" />
          <Spacer size="paddingXs" />
          <TextSmall>
            We ran into a problem with getting additional coverage data. Once
            data is available, they will be displayed here!
          </TextSmall>
        </VerticallyCentered>
      </div>
    );
  }

  if (status === "error" && licenseState === "licensed") {
    return (
      <Alert severity="error">
        <AlertTitle>Error getting the issue:</AlertTitle>
        <TextSmall>{error?.message}</TextSmall>
      </Alert>
    );
  }

  if (additionalCoverageError) {
    return (
      <div {...props}>
        <Spacer size="verticalXxl" />
        <H3>Additional Coverage</H3>
        <Spacer size="gutterS" />
        <VerticallyCentered>
          <SignalWifiOffIcon color="disabled" />
          <Spacer size="paddingXs" />
          <TextSmall>Unable to load Additional Coverage.</TextSmall>
        </VerticallyCentered>
      </div>
    );
  }
  const availableSortSections = fetchedSortSections ?? [];

  return withSkeleton(
    displayedSortSectionId && displayedSortSection && (
      <div {...props} {...(hasScrollSection && scrollSectionProps)}>
        <Spacer size="verticalXxl" />
        <H3 id="addcov">Additional Coverage</H3>
        <Spacer size="gutterS" />
        <AdditionalCoverageArticles
          sortSections={availableSortSections}
          inboxProductIssueId={issue?.inboxProductIssueId ?? undefined}
        />
        <Spacer size="gutterM" />
      </div>
    )
  );
}
