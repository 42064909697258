import * as React from "react";
import { useEffect, useState } from "react";
import { Divider, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { SOCIAL_MEDIA_MIN_WIDTH } from "@tbml/components/SocialMediaPlatform";
import { Slider } from "../Slider";

export type Props<T> = {
  groupedItems: T[][];
  initialIndex: number;
  itemWidth?: number;
  centerNavigation?: boolean;
  renderItem: (item: T, idx: number, lastLoaded: number) => JSX.Element;
  onIndexChange: (itemIndex: number) => void;
};

export function ArticlesSlider<T>({
  initialIndex,
  groupedItems,
  itemWidth = SOCIAL_MEDIA_MIN_WIDTH,
  centerNavigation = true,
  renderItem,
  onIndexChange,
}: Props<T>): JSX.Element {
  const [sliderProgress, setSliderProgress] = useState(0);
  const [activeStep, setActiveStep] = useState<{
    step: number;
    update: "UPDATE" | "SKIP";
  }>({ step: initialIndex, update: "SKIP" });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const sliderElements: JSX.Element[] = [];
  const totalItemsNoDividersCount = groupedItems.reduce(
    (count, items) => count + items.length,
    0
  );

  const lastArticleToLoadIndex =
    Math.floor(totalItemsNoDividersCount * sliderProgress) + 5;
  let groupIndex = 0;
  let articleIndex = 0;
  let allItemsCount = 0;
  const itemsToSkip: number[] = [];
  const dividersInfo: number[] = [];
  const artToAll = new Map<number, number>();
  const allToArt = new Map<number, number>();

  for (const group of groupedItems) {
    for (const article of group) {
      const el = renderItem(article, articleIndex, lastArticleToLoadIndex);
      sliderElements.push(
        <div
          key={`slide-${articleIndex}`}
          style={{
            maxWidth: itemWidth,
            width: itemWidth,
            marginLeft: "8px",
            marginRight: "8px",
          }}
        >
          {el}
        </div>
      );
      artToAll.set(articleIndex, allItemsCount);
      allToArt.set(allItemsCount, articleIndex);
      articleIndex += 1;
      allItemsCount += 1;
    }
    if (groupIndex !== groupedItems.length - 1) {
      dividersInfo.push(articleIndex - 1);
      sliderElements.push(
        <div
          key={`divider-${groupIndex}`}
          style={{
            maxWidth: 2,
            display: "flex",
            minHeight: "100px",
            alignSelf: "stretch",
          }}
        >
          <Divider
            key={`divider-${groupIndex}`}
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{
              width: "2px",
              bgcolor: "primary.main",
              borderColor: "unset",
              borderRightWidth: 0,
            }}
          />
        </div>
      );
      itemsToSkip.push(allItemsCount);
      allItemsCount += 1;
    }
    groupIndex += 1;
  }

  // need to add this on desktop to be able to browse
  // through all items and update the tabs accordingly
  if (!isMobile) {
    for (let k = 0; k < 2; k += 1) {
      sliderElements.push(
        <div
          key={`fill-${k}`}
          style={{ maxWidth: itemWidth, display: "flex" }}
        />
      );
    }
  }

  useEffect(() => {
    setActiveStep({ update: "UPDATE", step: initialIndex });
    setSliderProgress(initialIndex / (totalItemsNoDividersCount - 1));
    // needed  to prevent slider from sudden jumps due to the mapping changing
    // we just want this to be called whenever a tab change is triggered from the parent component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialIndex]);

  let navigationStyle;
  if (centerNavigation) {
    navigationStyle = { alignSelf: "center" };
  } else {
    navigationStyle = { top: "200px" };
  }

  return (
    <>
      <IconButton
        aria-label="previous-article-button"
        onClick={() => {
          const { step: currentIndex } = activeStep;
          const prevIndex = currentIndex - 1;
          if (prevIndex < 0) {
            return;
          }

          onIndexChange(prevIndex);
          setActiveStep({ update: "UPDATE", step: prevIndex });
          setSliderProgress(prevIndex / (totalItemsNoDividersCount - 1));
        }}
        sx={{
          border: 1,
          borderColor: "primary.main",
          borderRadius: 40,
          boxShadow: 5,
          opacity: activeStep.step === 0 ? 0 : 1,
          position: "absolute",
          ...navigationStyle,
          zIndex: 999,
          backgroundColor: "white",
          "&:hover": {
            backgroundColor: "white",
          },
        }}
        size="small"
      >
        <KeyboardArrowLeft color="primary" />
      </IconButton>
      <Slider
        // account for the margin on each element
        itemWidth={itemWidth + 16}
        itemCount={sliderElements.length}
        activeStep={activeStep}
        dividersInfo={dividersInfo}
        onScrollProgress={(progress) => {
          setSliderProgress(progress);
          const currentIndex = Math.floor(
            progress * (totalItemsNoDividersCount - 1)
          );
          setActiveStep({ update: "SKIP", step: currentIndex });
          onIndexChange(currentIndex);
        }}
      >
        {sliderElements}
      </Slider>
      <IconButton
        aria-label="next-article-button"
        sx={{
          border: 1,
          borderColor: "primary.main",
          borderRadius: 40,
          boxShadow: 5,
          position: "absolute",
          ...navigationStyle,
          right: 0,
          zIndex: 999,
          opacity: activeStep.step === totalItemsNoDividersCount - 1 ? 0 : 1,
          backgroundColor: "white",
          "&:hover": {
            backgroundColor: "white",
          },
        }}
        onClick={() => {
          const { step: currentIndex } = activeStep;
          const nextIndex = currentIndex + 1;
          if (nextIndex > totalItemsNoDividersCount - 1) {
            return;
          }
          onIndexChange(nextIndex);
          setActiveStep({ update: "UPDATE", step: nextIndex });
          setSliderProgress(nextIndex / (totalItemsNoDividersCount - 1));
        }}
        size="small"
      >
        <KeyboardArrowRight color="primary" />
      </IconButton>
    </>
  );
}
