import { useEffect } from "react";
import {
  atom,
  useRecoilValue,
  useSetRecoilState,
} from "@tbml/shared-dependencies/recoil";

type Id = string;
export const currentIssueIdAtom = atom<Id>({
  key: "currentIssueId",
});

// temporary hook to have easy access to properties that were already fetched
// can be changed in the future, by accessing state in react-query directly
export const useSyncCurrentIssueIdState = (
  issueId: Id | null | undefined
): void => {
  const setCurrentIssueId = useSetRecoilState(currentIssueIdAtom);
  useEffect(() => {
    if (issueId) {
      setCurrentIssueId(issueId);
    }
  }, [issueId, setCurrentIssueId]);
};

export const useCurrentIssueIdValue = (): Id =>
  useRecoilValue(currentIssueIdAtom);
