import {
  atomFamily,
  SetterOrUpdater,
  useRecoilState,
} from "@tbml/shared-dependencies/recoil";

type SortSectionId = string | null;
const sortSectionIdAtom = atomFamily<SortSectionId, string>({
  key: "selectedSortSectionAtom",
  default: null,
});

export const useSelectedSortSectionIdState = (
  issueId: string
): [SortSectionId, SetterOrUpdater<SortSectionId>] =>
  useRecoilState(sortSectionIdAtom(issueId));
